<template>
  <v-container>
    <page-title :title="storeName" />

    <div class="mb-5">
      <datetime-picker
          placeholder="選擇時間區間"
          range
          v-model="date"
          hide-details
          dense
          clearable
      ></datetime-picker>
    </div>

    <template v-if="showAction">
      <p class="pb-2 border-bottom-1 mb-2">請勾選要請款之訂單</p>
      <v-divider/>

      <div class="d-flex align-center pt-2">
        <v-checkbox
            v-model="isSelectAll"
            dense
            hide-details
        ></v-checkbox>
        <span>全選</span>
      </div>

      <div class="mb-10">
        <apply-pay-card
            v-for="(item, index) in cardData"
            :key="index"
            v-model="cardData[index]"
            :configs="cardConfigs(index)"
        ></apply-pay-card>
      </div>

      <v-btn
          width="100%"
          @click="applyPay"
          class="primary"
      >
        請款
      </v-btn>
    </template>

    <p v-else>無相關訂單</p>

    <check-modal
        v-if="showModal"
        title="確認是否送出"
        @apply="apply"
        @close="close"
    ></check-modal>

  </v-container>
</template>

<script>
import discountMixins from "mixins/discount.js"
import countMixins from "mixins/count"
import qs from "qs"
export  default  {
  mixins: [discountMixins, countMixins],
  components: {
    'apply-pay-card': () => import('modules/base/components/priceCard/applyPay/applyPayCard.vue'),
    'check-modal': () => import('modules/provider/components/modal/checkModal.vue')
  },
  data: () => ({
    init: false,
    data: [],
    cardData: null,
    date: null,
    showModal: false,
    storeInfo: null,
  }),
  computed: {
    storeName() {
      if(!this.storeInfo) return null
      return this.storeInfo.name
    },
    query() {
      return {
        filter: qs.stringify(this.paramsFilter, { arrayFormat: 'indices' })
      }
    },
    filterDate() {
      if(!this.date) return null
      const filteredEmpty = this.date.filter(item => item != null)
      if(filteredEmpty.length == 0) return null
      return {
        created_at: filteredEmpty
      }
    },
    paramsFilter() {
      const paramsFilter = {filter: null}
      if(this.filterDate) {
        paramsFilter.filter = {...this.filterDate}
      }
      return paramsFilter
    },
    params() {
      const params = {
        ...this.paramsFilter
      }
      return params
    },
    storeId() {
      return this.$route.params.storeId
    },
    providerId() {
      return this.$store.getters['member/providerId']
    },
    showAction() {
      if(window.eagleLodash.isEmpty(this.data)) return false
      return this.data.length > 0
    },
    isCheckedList() {
      if(!this.cardData) return []
      return this.cardData
          .filter(data => data.checked)
    },
    checkedOrderIds() {
      return this.isCheckedList.map(item => item.order_id)
    },
    isSelectAll: {
      get() {
        if(!this.cardData) return false
        if(this.cardData.length == 0) return false
        return this.cardData.filter(item => item.checked).length == this.cardData.length
      },
      set(value) {
        this.cardData.forEach(item => item.checked = value)
      }
    },
  },
  watch: {
    data: {
      deep: true,
      async handler() {
        this.cardData = this.data.map(item => {
          return {
            order_id: item.order_id,
            price: item.price,
            date: item.date,
            checked: false, // checkbox
          }
        })
      }
    },
    query: {
      deep: true,
      async handler(newValue, oldValue) {
        if(!this.init) return
        if(this.$eagleLodash.isEqual(newValue, oldValue)) return
        this.$router.push({query: this.query})
        await this.getStoreApplyPay()
      }
    },
  },
  async created() {
    this.init = false
    this.parseQuery()
    this.getStoreInfo()
    await this.getStoreApplyPay()
    this.init = true
  },
  methods: {
    async getStoreInfo() {
      const res = await this.$api.collection.storeApi.readByProviderAndStore(this.storeId, this.providerId)
      this.storeInfo = res
    },
    parseQuery() {
      const query = this.$eagleLodash.cloneDeep(this.$route.query)
      if(query.filter) {
        const filter = qs.parse(this.$route.query.filter)
        this.date = filter.created_at
      }
    },
    close() {
      this.showModal = false
    },
    async apply() {
      await this.callApplyPayApi()
    },
    async getStoreApplyPay() {
      this.$store.dispatch('loading/active')
      try {
        const res = await this.$api.collection.applyPayApi.getStoreApplyPay({
          provider: this.providerId,
          store: this.storeId,
          params: this.params
        })

        this.data = res.map(item => {
          return {
            order_id: item.order_no,
            date: item.created_at,
            price: item.total
          }
        })

      } catch(err) {
        console.error(err)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    cardConfigs(index) {
      if(window.eagleLodash.isEmpty(this.data)) return []
      const item = this.data[index]
      const configs = [
        {
          label: '訂單編號',
          value: item.order_id,
          checkbox: {
            show: true,
          },
          class: 'secondary--text'
        },
        { label: "日期", value: item.date ,col: 6 },
        { label: "金額", value: item.price, valueSuffix: '元', fixed: 2, col: 6 , class: 'primary--text'},
      ]

      return configs
    },
    async applyPay() {
      if(this.isCheckedList.length == 0) {
        this.$snotify.warning('請選擇訂單')
        return;
      }
      this.showModal = true
    },
    async callApplyPayApi() {
      this.$store.dispatch("loading/active");
      try {
        const params = {
          orderIds: this.checkedOrderIds
        };
        await this.$api.collection.applyPayApi.create(
            {provider: this.providerId, store: this.storeId, params}
        );
        this.checkedOrderIds.forEach((orderId) => {
          const index = this.data.findIndex(item => item.order_id == orderId)
          this.$delete(this.data, index)
        })
        this.$snotify.success("請款成功");
      } catch (err) {
        console.error(err);
        this.$snotify.error("發生錯誤");
      } finally {
        this.$store.dispatch("loading/close");
        this.showModal = false
      }
    },
  },
}
</script>